import { HomePage } from "@stss/ui/src/pages";
import type { GetServerSidePropsContext } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import { getServerSession } from "@calcom/features/auth/lib/getServerSession";

function RedirectPage() {
  return <HomePage />;
}

export async function getServerSideProps({ req, res, locale }: GetServerSidePropsContext) {
  const session = await getServerSession({ req, res });

  if (session?.user?.id) {
    return { redirect: { permanent: false, destination: "/bookings/upcoming" } };
  }

  return {
    props: {
      ...(await serverSideTranslations(locale || "", ["common"])),
    },
  };
}

export default RedirectPage;
