import {
  Translate,
  LandingPage,
  Header,
  Footer,
  WhatWeDo,
  BookingInfoGraphic,
  WhatYouDo,
  MemberReportOutcomes,
  HelpMembersSeeResult,
  CallToAction,
  PageHead,
} from "@stss/ui";
import type { FooterColumn } from "@stss/ui/src/layout/Footer";
import type { Outcome } from "@stss/ui/src/layout/MemberReportOutcomes";
import type { WhatWeDoItem } from "@stss/ui/src/layout/WhatWeDo";
import type { WhatYouDoItem } from "@stss/ui/src/layout/WhatYouDo";
import Link from "next/link";
import React from "react";

import { useLocale } from "@calcom/lib/hooks/useLocale";

const CONSTANTS = {
  websiteLink: "https://www.simpletherapy.com",
  loginPage: "/auth/login",
  ctaJointLink: "https://app.simpletherapy.com/register",
  supportEmail: process.env.NEXT_PUBLIC_SUPPORT_EMAIL || "",
  providerEmail: "providers@simpletherapy.com",
  faqLink: "https://help.simpletherapy.com/en",
};

export function HomePage() {
  const { t } = useLocale();

  const whatWeDoItems: WhatWeDoItem[] = [
    { i18key: "what_we_do_schedule", icon: "1-min.png" },
    { i18key: "what_we_do_calendars", icon: "2-min.png" },
    { i18key: "what_we_do_availability", icon: "3-min.png" },
    { i18key: "what_we_do_therapists", icon: "4-min.png" },
  ];

  const whatYouDoItems: WhatYouDoItem[] = [
    {
      i18keyTitle: "what_you_do_reinforce_title",
      i18keySubTitle: "what_you_do_reinforce_description",
      icon: "team-min.png",
    },
    {
      i18keyTitle: "what_you_do_treat_title",
      i18keySubTitle: "what_you_do_treat_description",
      icon: "heart-min.png",
    },
    {
      i18keyTitle: "what_you_do_validate_title",
      i18keySubTitle: "what_you_do_validate_description",
      icon: "note-min.png",
    },
  ];

  const outcomes: Outcome[] = [
    {
      value: 89,
      iconSrc: "improvement_icon-min.png",
      text: (
        <span>
          <Translate i18nKey="outcomes_participant_improvements" />
        </span>
      ),
    },
    {
      value: 65,
      iconSrc: "pain_icon-min.png",
      text: (
        <span>
          <Translate i18nKey="outcomes_pain_reduction" />
        </span>
      ),
    },
    {
      value: 39,
      iconSrc: "opioid_icon-min.png",
      text: (
        <span>
          <Translate i18nKey="outcomes_stopped_opioids" />
        </span>
      ),
    },
  ];

  const footerMenu: FooterColumn[] = [
    {
      columnName: t("product"),
      items: [
        {
          title: t("work_with_company", { companyName: t("st_brand_name") }),
          link: "/work-with-simpletherapy",
        },
        { title: t("outcomes"), link: "/outcomes/" },
        { title: t("register"), link: "/register" },
        { title: t("security"), link: "/security" },
      ],
    },
    {
      columnName: t("company"),
      items: [
        { title: t("about_company", { companyName: t("st_brand_name") }), link: "/about" },
        { title: t("careers"), link: "/careers" },
        { title: t("press_and_publications"), link: "/press-and-publications" },
        { title: t("company_blog"), link: "/blog" },
      ],
    },
    {
      columnName: t("resources"),
      items: [
        { title: t("faq"), link: CONSTANTS.faqLink },
        { title: t("company_privacy_policy"), link: "/privacy-policy" },
        { title: t("company_terms_of_service"), link: "/about/terms-conditions" },
        { title: t("company_contact"), link: "/contact" },
      ],
    },
  ];

  return (
    <LandingPage
      title={t("seo_landing_page_title", { companyName: t("st_brand_name") })}
      description={t("seo_landing_page_description", { companyName: t("st_brand_name") })}>
      <Header
        logoLink={CONSTANTS.websiteLink}
        logoTitle={t("website") + " " + t("st_brand_name")}
        ctaLink={CONSTANTS.loginPage}
        ctaTitle={t("provider") + " " + t("login")}
      />
      <main className="flex-auto overflow-x-hidden">
        <div className="container m-auto 2xl:max-w-[1140px]">
          <PageHead
            upperTitleNode={<Translate i18nKey="banner_over_title" />}
            titleNode={<Translate i18nKey="banner_title" />}
            subtitleNode={<Translate i18nKey="banner_sub_title" />}
            callToActionNode={
              <Link
                href={CONSTANTS.ctaJointLink}
                className="font-av border-stprimary-orange-900 bg-stprimary-orange-900 hover:stprimary-orange-900 hover:text-stprimary-orange-900 inline-block rounded border-2 px-[70px] py-2 text-[15px] font-extrabold text-white hover:bg-white md:text-[18px] lg:text-[23px]"
                target="_blank"
                rel="noreferrer">
                <Translate i18nKey="join_us" />
              </Link>
            }
            imageSrc="/st/img/page_head_home.png"
            imagePseudoSchema="schema-2"
          />
        </div>
        <div className="relative z-10 px-5 py-3 pb-8 pt-9 md:pb-12 md:pt-16 lg:pb-16 lg:pt-20">
          <div className="container m-auto 2xl:max-w-[1140px]">
            <WhatWeDo items={whatWeDoItems} />
          </div>
        </div>
        <div className="relative z-10 pb-14 pt-3 md:pt-7 lg:pb-16 lg:pt-10">
          <div className="container m-auto mb-1.5 2xl:max-w-[1140px]">
            <BookingInfoGraphic />
          </div>
        </div>
        <div className="bg-stBackground-darken relative z-10 pb-11 pt-9 md:pb-20 md:pt-16 lg:pb-24 lg:pt-20">
          <div className="container m-auto 2xl:max-w-[1140px]">
            <WhatYouDo items={whatYouDoItems} />
          </div>
        </div>
        <div className="relative z-10 pb-0 pb-11 lg:pb-10">
          <HelpMembersSeeResult />
        </div>
        <div className="relative z-10 py-3 pb-11 pt-9 md:pb-20 md:pt-0 lg:pb-24">
          <div className="container m-auto 2xl:max-w-[1140px]">
            <MemberReportOutcomes outcomes={outcomes} />
          </div>
        </div>
        <div className="bg-stBackground-lightBlue relative z-10 py-16 md:py-20 lg:py-24">
          <div className="container m-auto 2xl:max-w-[1140px]">
            <CallToAction
              text={t("cta_join_title")}
              ctaText={t("cta_button_text", { companyName: t("st_brand_name") })}
              ctaLink={CONSTANTS.ctaJointLink}
              targetBlank={true}
            />
          </div>
        </div>
      </main>
      <Footer
        websiteLink={CONSTANTS.websiteLink}
        footerMenu={footerMenu}
        supportEmail={CONSTANTS.supportEmail}
        providerEmail={CONSTANTS.providerEmail}
      />
    </LandingPage>
  );
}
